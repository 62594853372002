import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import styles from '../../../mobile/screens/CatalogueV2/styles.module.scss'
import greenArrow from '../../../assets/images/catalogueV2/greenArrowRight.svg'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import GroupSelector from "../../../mobile/screens/CatalogueV2/components/GroupSelector";
import {useWindowDimensions} from "../../../mobile/screens/Catalogue";
import BigList from "../../../components/BigList";
import ProductCard from "../../ProductCard/ProductCard";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import CatalogueCarouselV2 from "../components/CatalogueCarouselV2";
import {Helmet} from "react-helmet";

const ZERO_HEIGHT = 0;
const TAG_HEIGHT = 57;
const FULL_HEIGHT = 67;

function createMap(category, map) {
    let sections = []; // Ð´Ð»Ñ ÑÐ¿Ð¸ÑÐºÐ°
    let selectorList = []; // Ð´Ð»Ñ ÑÐµÐ»ÐµÐºÑ‚Ð¾Ñ€Ð° ÑÐ²ÐµÑ€Ñ…Ñƒ
    let info = []; // Ð´Ð»Ñ Ñ…ÑÐ´ÐµÑ€Ð¾Ð² ÑÐµÐºÑ†Ð¸Ð¹

    if (category.type === 'master') {
        for (let childId of category.children) {
            const child = map[childId];
            if (!child) continue;

            selectorList.push({
                index: sections.length,
                name: child.name,
                id: childId,
            });

            for (let tagId of child.children) {
                const tag = map[tagId];
                if (!tag?.products?.length) continue;

                sections.push(tag.products);
                info.push({
                    isFirst: tag.isFirst,
                    name: tag.name,
                    parentName: child.name,
                    id: tagId,
                    parentId: childId,
                    height: tag.isFirst ? FULL_HEIGHT : TAG_HEIGHT,
                });
            }
            if (child.products?.length) {
                sections.push(child.products);
                info.push({
                    isFirst: !child.children.length,
                    name: 'ÐŸÑ€Ð¾Ñ‡ÐµÐµ',
                    parentName: child.name,
                    id: childId,
                    parentId: childId,
                    height: TAG_HEIGHT,
                });
            }
        }
        if (category.products?.length) {
            sections.push(category.products);
            info.push({
                isFirst: false,
                name: null,
                parentName: null,
                id: category.id,
                parentId: null,
                height: ZERO_HEIGHT,
            });
        }

        return {sections, selectorList, info};
    }
    if (category.type === 'child') {
        for (let tagId of category.children) {
            const tag = map[tagId];
            if (!tag?.products?.length) continue;

            selectorList.push({
                index: sections.length,
                name: tag.name,
                id: tagId,
            });

            sections.push(tag.products);
            info.push({
                isFirst: false,
                name: tag.name,
                parentName: category.name,
                id: tagId,
                parentId: category.id,
                height: TAG_HEIGHT,
            });
        }

        if (category.products?.length) {
            sections.push(category.products);
            info.push({
                isFirst: false,
                name: category.children?.length ? 'ÐŸÑ€Ð¾Ñ‡ÐµÐµ' : null,
                parentName: null,
                id: category.id,
                parentId: null,
                height: category.children?.length ? TAG_HEIGHT : ZERO_HEIGHT,
            });
        }

        return {sections, info, selectorList};
    }
}

const fillCategoryWithPersonalProducts = (category, products, user) =>
{
    let useCategory = Object.assign({}, {...category})
    if (!user || useCategory.id !== -100) {
        return useCategory
    }

    useCategory.products = user.personalRecommendations.filter(id => !!products[id]);

    return useCategory
}

const CategoryScreenDesktop = () => {
    const {url} = useParams();
    const user = useSelector(state => state.user)
    const products = useSelector(state => state.catalogue.products)
    const map = useSelector((state) => state.catalogue.catV6.map);
    const ids = useSelector(state => state.catalogue.urlMap[url])
    const id = map[ids.childId].url === url ? ids.childId : ids?.parentId

    const category = id == -100 ? fillCategoryWithPersonalProducts(map[id], products, user) : map[id];

    const listRef = useRef(null);
    const navigate = useNavigate()
    const [categoryId, setCategoryId] = useState({parentId: category.id})
    const [pageTitle, setPageTitle] = useState('')
    const catalogue = useSelector(state => state.catalogue)
    const location = useLocation()

    useEffect(() => {
        setPageTitle({
            title: catalogue.categories.slave.map[ids.childId].metaTitle,
            keyWords: catalogue.categories.slave.map[ids.childId].metaKeywords,
            description: catalogue.categories.slave.map[ids.childId].metaDescription
        })

    }, [location.pathname])

    useEffect(() => {
        scrollToTop();
    }, [id]);
    const {selectorList, sections, info} = useMemo(() => createMap(category, map), [category, map]);
    const [visibleSection, setVisibleSection] = useState(selectorList[0]?.id);

    function renderSectionHeader(index) {
        const data = info[index];
        return <SectionHeader data={data}/>;
    }

    function scrollToIndex(index) {
        if (listRef.current)
            listRef.current.scrollToLocation({
                sectionIndex: index,
            });
    }

    function scrollToTop(animated = false) {
        if (listRef.current) {
            listRef.current.scrollToLocation({
                sectionIndex: 0,
            });
        }
    }

    const onViewableItemsChanged = useCallback(
        (viewableItems) => {
            const sectionIndex = viewableItems?.[0]?.sectionIndex;

            let visibleSection = false;

            for (let selectorItem of selectorList) {
                if (selectorItem.index <= sectionIndex) visibleSection = selectorItem.id;
                else break;
            }
            if (visibleSection) setVisibleSection(visibleSection);
        },
        [category]
    );

    function onCategoryPress(id) {
        setCategoryId(id)
        navigate('Category', {id})
    }

    const {width: windowWidth} = useWindowDimensions()

    const numColumns = Math.floor((windowWidth > 1439 ? 1302 : 895) / 170)
    const width = (windowWidth > 1439 ? 1302 : 895) / numColumns + 'px'

    return (
        <div>
            <Helmet>
                <title>{pageTitle.title}</title>
                <meta name="viewport"
                      content="width=device-width, initial-scale=0.9, maximum-scale=0.9, user-scalable=no"/>
                <meta name="description" content={pageTitle.description}/>
                <meta name="keywords" content={pageTitle.keyWords}/>
            </Helmet>
            <div style={{position: 'sticky', top: 100, left: 0, zIndex: 2, background: '#fff', padding: '0 0 5px'}}>
                <CatalogueCarouselV2 currentSection={categoryId} setCurrentSection={(id) => onCategoryPress(id)}/>
            </div>
            <div style={{background: '#fff', position: 'sticky', top: 195, left: 0, zIndex: 19}}>
                <div className={styles.stickyHeader}>
                    <GroupSelector
                        selectorList={selectorList}
                        activeId={visibleSection}
                        scrollFunction={scrollToIndex}
                    />
                </div>
            </div>
            <div style={{marginTop: !selectorList.length ? 30 : 0}} className={styles.subcategoryWrapper}>
                <BigList
                    sections={sections}
                    ref={listRef}
                    itemHeight={335}
                    numColumns={numColumns}
                    renderSectionHeader={renderSectionHeader}
                    onViewableItemsChange={onViewableItemsChanged}
                    sectionHeaderHeight={(index) => info[index]?.height || 0}
                    renderItem={item => renderItem(item, width)}
                />

                <NextButton nextId={category.next}/>

            </div>
        </div>
    );
};

function renderItem(item, width) {
    return <div style={{height: 335, width, padding: 5}}><ProductCard id={item}/></div>
}

const SectionHeader = ({data}) => {
    const navigate = useNavigate();
    const map = useSelector((state) => state.catalogue.catV6.map[data.parentId]);

    if (!data.height) return null;

    function onNavigateToCat(id) {
        window.onChildClick = true
        window.scrollTo(0, 0)
        navigate(`/${map.url}`);
    }

    return (
        <div className={styles.sectionHeaderContainer} style={{height: data.height}}>
            {data.isFirst && (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 6}}>
                    <SText color={'#929292'} size={20} weight={900} style={{flex: 1}}>
                        {data.parentName}
                    </SText>
                    <div className={styles.moreBtn} onClick={() => {
                        onNavigateToCat(data.parentId)
                    }}>
                        <SText size={14} weight={700} color={Colors.green}>
                            {'Ðš Ñ€Ð°Ð·Ð´ÐµÐ»Ñƒ'}
                        </SText>
                    </div>
                </div>
            )}
            {data.name && (
                <SText size={14} weight={700} color={'#929292'}>
                    {data.name}
                </SText>
            )}
        </div>
    );
};

const NextButton = ({nextId}) => {
    const map = useSelector((state) => state.catalogue.catV6.map);
    const next = map[nextId] || map[0];
    const navigate = useNavigate();

    function onPress() {
        window.scrollTo(0, 0)
        navigate(`/${next.url || map[0].url}`);
    }

    return (
        <div className={styles.nextCategoryWrapper}>
            <SText weight={500} size={11} color={'#929292'}>
                {'ÐŸÐµÑ€ÐµÐ¹Ð´Ð¸ Ð´Ð°Ð»ÐµÐµ Ð² Ñ€Ð°Ð·Ð´ÐµÐ»'}
            </SText>
            <div onClick={onPress} className={styles.nextButton}>
                <SText size={14} weight={700}>{next.name}</SText>
                <img src={greenArrow}/>
            </div>
        </div>
    );
};

export default CategoryScreenDesktop;