import React, { useEffect, useState } from 'react';
import sendRequest from "../../api";
import { Box, Modal } from "@mui/material";
import styles from './AddCard.module.scss'
import { useSelector } from "react-redux";
import closeBtn from "../../assets/images/closeModal.svg";
import { useWindowDimensions } from "../../components/utils/hooks";
import arrow from '../../assets/images/cart/goBack.svg'
import SText from "../../components/SText";
import { useNavigate } from "react-router-dom";

const AddCard = ({ onClose, onSuccess = () => { }, orderId, sbp = false }) => {
    const [iframe, setIframe] = useState(false)
    const USER = useSelector(state => state.user)
    const width = useWindowDimensions()
    const navigate = useNavigate()

    useEffect(() => {
        getAddCardUrl();
        window.addEventListener("message", handleFrameTasks);
        return () => window.removeEventListener("message", handleFrameTasks);
    }, [])

    useEffect(() => {
        return () => onSuccess()
    }, [])

    const getAddCardUrl = async () => {
        let TOKEN = 'UNREGISTERED';
        if (USER) {
            TOKEN = USER.apikey;
        }

        if (orderId) {
            // if (sbp) {
            //     window.location.replace('https://ecomarket.ru/sbp/' + orderId)
            // }
            window.location.replace('https://ecomarket.ru/qr_id/' + orderId)
            return;
        }

        try {
            let SEND_DATA = {
                apikey: TOKEN,
                sbp: false,
                security: ''
            };
            let data = await sendRequest('getAddCardUrl', SEND_DATA);
            if (data !== false) {
                setIframe(data.URL)
            }
        } catch (error) {
            console.log(error);
            console.log('BAD PRODUCTS TIMER');
        }
    }

    const handleFrameTasks = async (e) => {
        try {
            let DATA = JSON.parse(e.data);
            if (DATA.Success) {
                await onSuccess()
            }
        } catch (error) {
        }
    }

    return <Modal
        open={ true }
        onClose={ onClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={ { display: 'flex', alignItems: 'center', justifyContent: 'center' } }
    >
        <Box style={ { display: 'flex' } }>
            <div className={ styles.addCardModal }>
                { width <= 1023 && <div onClick={ () => navigate(-1) } className={ styles.header }>
                    <img src={ arrow } />
                    <SText size={ 16 } weight={ 700 } lineHeight={ 16 }>{ 'Ð”Ð¾Ð±Ð°Ð²Ð»ÐµÐ½Ð¸Ðµ ÐºÐ°Ñ€Ñ‚Ñ‹' }</SText>
                </div> }
                <iframe name="iframe" src={ iframe }
                    style={ {
                        display: 'block',
                        height: '100%',
                        width: '100%',
                        marginBottom: '20px',
                        border: 'none'
                    } }>
                    Ð’Ð°Ñˆ Ð±Ñ€Ð°ÑƒÐ·ÐµÑ€ Ð½Ðµ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶Ð¸Ð²Ð°ÐµÑ‚ Ð¿Ð»Ð°Ð²Ð°ÑŽÑ‰Ð¸Ðµ Ñ„Ñ€ÐµÐ¹Ð¼Ñ‹!
                </iframe>
            </div>
            { width > 1023 && <div onClick={ onClose } className={ styles.closeModal }><img src={ closeBtn } /></div> }
        </Box>
    </Modal>
}

export default AddCard
