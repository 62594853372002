import React, {useEffect, useRef, useState} from "react";
import styles from './styles.module.scss'
import BottomModal from "../../components/BottomModal";
import {useDispatch, useSelector} from "react-redux";
import {createHash} from "../../../components/utils/other";
import sendRequest from "../../../api";
import {writeApiKey} from "../../../redux/reducers/user";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import PhoneInput from "../../../screens/Auth/components/PhoneInput";
import waGray from "../../../assets/images/auth/waGray.svg";
import waGreen from "../../../assets/images/auth/waGreen.svg";
import CodeInput from "./components/CodeInput";
import {useQueryParams} from "../../../components/utils/ModalSpectator";
import back from '../../../assets/images/catalogueV2/backArrow.svg'
import greenTick from '../../../assets/images/auth/greenTick.svg'
import refresh from '../../../assets/images/auth/refresh.svg'
import phoneIcon from '../../../assets/images/auth/phoneIcon.svg'
import {useWindowDimensions} from "../../../components/utils/hooks";

const [SIGN_IN, CONFIRM_PHONE, CONFIRM_WA] = [0, 1, 2]

const Auth = ({onClose}) => {
    const scrollRef = useRef(null)
    const {get} = useQueryParams()
    const [scene, setScene] = useState(SIGN_IN)
    const [phone, setPhone] = useState('')
    const [agreed, setAgreed] = useState(true)
    const [status, setStatus] = useState(null)
    const [code, setCode] = useState('')
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const checkout = !!get('auth')?.length
    const [orderNumber, setOrderNumber] = useState(undefined)

    useEffect(() => {
        const initialPhone = get('auth')
        if (!initialPhone) return
        const phone = !initialPhone.includes('+') ? '+' + initialPhone.trim() : initialPhone
        if (window?.newUserOrderId) {
            setOrderNumber(window.newUserOrderId)
        }
        setPhone(phone)
        requestCall(phone)
        return () => {
            window.newUserOrderId = null
            window.newUserPhone = null
        }
    }, [])

    async function requestCall(_phone = phone) {
        const payload = {
            phone: _phone,
            token: user.authToken,
            sum: createHash(_phone),
            source: 'app',
            type: 'call'
        }
        sendRequest('doRegisterOrRecover', payload)

        setScene(CONFIRM_PHONE)
    }

    async function requestWA() {
        const payload = {
            action: 'doGetCodeOnWA',
            source: 'app',
            sum: createHash(phone),
            type: 'call',
            phone,
            token: user.authToken,
        }
        sendRequest('doGetCodeOnWA', payload)
        setCode('')
        setScene(CONFIRM_WA)
    }

    async function confirmCode(code) {
        const payload = {
            source: 'app',
            token: user.authToken,
            phone,
            code,
            newUser: !!window?.newUserOrderId,
        }
        const response = await sendRequest('doConfirm', payload)
        if (response?.user) {
            const date = new Date();
            date.setUTCMilliseconds(response.user.time)
            document.cookie = 'eco=' + response.user.eco + '; expires=' + date.toUTCString() + '; path=/;'
            setStatus('success')
            dispatch(writeApiKey(response.user.TOKEN))

        } else {
            setStatus('error')
        }
    }

    useEffect(() => {
        if (status === 'success') onClose()
    }, [status])

    function onReenter() {
        setScene(SIGN_IN)
        setCode('')
    }

    let content = null
    switch (scene) {
        case SIGN_IN:
            content = <SignIn phone={phone}
                              setPhone={setPhone}
                              onCall={requestCall}
                              onWA={requestWA}
                              agreed={agreed}
                              setAgreed={setAgreed}
                              orderNumber={orderNumber}
            />;
            break;
        case CONFIRM_PHONE:
            content = <ConfirmPhone code={code}
                                    setCode={setCode}
                                    phone={phone}
                                    onReenter={onReenter}
                                    onWA={requestWA}
                                    status={status}
                                    onSubmit={confirmCode}
                                    onDismissError={() => setStatus(null)}
                                    orderNumber={orderNumber}
            />;
            break;
        case CONFIRM_WA:
            content = <ConfirmWA code={code}
                                 setCode={setCode}
                                 phone={phone}
                                 onReenter={onReenter}
                                 onSubmit={confirmCode}
                                 status={status}
                                 onDismissError={() => setStatus(null)}
                                 orderNumber={orderNumber}
            />;
            break;
    }
    useEffect(() => {
        if (scrollRef.current)
            scrollRef.current.scrollTo({
                top: 0,
                behavior: "smooth"
            })
    }, [])

    return <BottomModal onClose={onClose} ref={scrollRef}>
        <div className={checkout ? styles.containerOrder : styles.container}>
            {content}
        </div>
    </BottomModal>
}

export const SignIn = ({phone, setPhone, orderNumber, onCall}) => {
    const disabled = phone.length !== 16
    const {get} = useQueryParams()
    const checkout = !!get('auth')?.length

    if (checkout) return <div>
        <div className={styles.header}>
            <div className={styles.orderTick}>
                <img src={greenTick}/>
                <SText size={18} weight={700} color={Colors.green}>{'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚'}</SText>
            </div>
            <div className={styles.orderNumber}>
                <SText size={14} weight={500} lineHeight={17}
                       color={Colors.darkGray}>{'â„– ' + orderNumber}</SText>
            </div>
        </div>
        <div style={{marginBottom: 13, textAlign: 'center'}}><SText size={18} weight={700} lineHeight={23}
                                                                    color={Colors.darkGray}>{'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}</SText>
        </div>
        <div className={styles.phoneNumberBlock}>
            <SText size={14} weight={500} lineHeight={17}
                   color={Colors.darkGray}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð¼ Ð²Ð°Ð¼ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ Ñ 4-Ñ… Ð·Ð½Ð°Ñ‡Ð½Ñ‹Ð¼ ÐºÐ¾Ð´Ð¾Ð¼'}</SText> <br/>
        </div>
        <PhoneInput onEnter={onCall} value={phone} onValueChange={setPhone}/>
        <button disabled={disabled} style={{background: disabled ? '#979797' : '#43b02a', marginTop: 150}}
                onClick={() => onCall()}
                className={styles.callBtn}>{'ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ'}
        </button>
        <div className={styles.agreements}>
            <SText size={10} weight={500} lineHeight={11}
                   color={'#9C9C9C'}>{'ÐÐ°Ð¶Ð¸Ð¼Ð°Ñ â€œÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒâ€, Ñ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÑŽÑÑŒ Ñ Ð£ÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸ Ð¿Ñ€Ð¾Ð´Ð°Ð¶Ð¸,'}</SText>
            <br/>
            <SText size={10} weight={500} lineHeight={11}
                   color={'#9C9C9C'}>{'ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ¾Ð¹ ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸ Ð¸ ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ¾Ð¹ Ð² Ð¾Ñ‚Ð½Ð¾ÑˆÐµÐ½Ð¸Ð¸ Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÐ¸ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ….'}</SText>
        </div>
    </div>

    return <div style={{
        padding: '80px 24px 25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    }}>
        <div>
            <div style={{marginBottom: 3}}><SText size={20} weight={700} color={Colors.darkGray}

                                                  lineHeight={25}>{'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}</SText>
            </div>
            <div style={{marginBottom: 20}}><SText size={14} weight={500} lineHeight={19}
                                                   color={'#7F7F7F'}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð¼ Ð²Ð°Ð¼ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ Ñ 4-Ñ… Ð·Ð½Ð°Ñ‡Ð½Ñ‹Ð¼ ÐºÐ¾Ð´Ð¾Ð¼'}</SText>
            </div>
            <PhoneInput onEnter={onCall} value={phone} onValueChange={setPhone}/>
            <button disabled={disabled} style={{background: disabled ? '#979797' : '#43b02a', marginTop: 200}}
                    onClick={() => onCall()}
                    className={styles.callBtn}>{'ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ'}
            </button>
        </div>
        <div className={styles.agreements}>
            <SText size={10} weight={500} lineHeight={11}
                   color={'#9C9C9C'}>{'ÐÐ°Ð¶Ð¸Ð¼Ð°Ñ â€œÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒâ€, Ñ ÑÐ¾Ð³Ð»Ð°ÑˆÐ°ÑŽÑÑŒ Ñ Ð£ÑÐ»Ð¾Ð²Ð¸ÑÐ¼Ð¸ ' +
                'Ð¿Ñ€Ð¾Ð´Ð°Ð¶Ð¸, ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ¾Ð¹ ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸ Ð¸ ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ¾Ð¹ Ð² Ð¾Ñ‚Ð½Ð¾ÑˆÐµÐ½Ð¸Ð¸ Ð¾Ð±Ñ€Ð°Ð±Ð¾Ñ‚ÐºÐ¸ Ð¿ÐµÑ€ÑÐ¾Ð½Ð°Ð»ÑŒÐ½Ñ‹Ñ… Ð´Ð°Ð½Ð½Ñ‹Ñ….'}</SText>
        </div>

    </div>
}

export const ConfirmPhone = ({
                                 phone = '+7-999-999-99-99',
                                 onReenter,
                                 onWA,
                                 onDismissError,
                                 status,
                                 onSubmit,
                                 code,
                                 setCode,
                                 orderNumber,
                             }) => {
    const timer = useRef(0)
    const [showHint, setShowHint] = useState(false)
    const {get} = useQueryParams()
    const checkout = !!get('auth')?.length
    const width = useWindowDimensions()

    useEffect(() => {
        onDismissError()
        if (code.length === 4)
            onSubmit(code)
    }, [code])

    useEffect(() => {
        timer.current = setTimeout(() => {
            setShowHint(true)
        }, 10000)

        return () => clearTimeout(timer.current)
    }, [])

    if (checkout) return <div>
        <div className={styles.header}>
            <div className={styles.orderTick}>
                <img src={greenTick}/>
                <SText size={18} weight={700} color={Colors.green}>{'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚'}</SText>
            </div>
            <div className={styles.orderNumber}>
                <SText size={14} weight={500} lineHeight={17}
                       color={Colors.darkGray}>{'â„– ' + orderNumber}</SText>
            </div>
        </div>
        <div style={{marginBottom: 13, textAlign: 'center'}}><SText size={18} weight={700} lineHeight={23}
                                                                    color={Colors.darkGray}>{'ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}</SText>
        </div>
        <div className={styles.phoneNumberBlock}>
            <SText size={14} weight={500} lineHeight={17}
                   color={Colors.darkGray}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ ÑÐ¼Ñ Ñ ÐºÐ¾Ð´Ð¾Ð¼ Ð½Ð° Ð½Ð¾Ð¼ÐµÑ€'}</SText> <br/>
            <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>{phone}</SText> <SText
            onClick={onReenter} className={styles.switchNumber} size={14} weight={500} lineHeight={17}
            color={'#bfbfbf'}>{'Ð´Ñ€ÑƒÐ³Ð¾Ð¹ Ð½Ð¾Ð¼ÐµÑ€'}</SText>
        </div>
        <div style={{border: status === 'error' ? '1px solid #ff5252' : '1px solid #f7f7f7', margin: '0 auto 13px'}}
             className={styles.codeInputWrapper}>
            <CodeInput code={code} setCode={setCode}/>
        </div>
        <div style={{margin: '0 auto 16px', opacity: status === 'error' ? 1 : 0, textAlign: 'center'}}><SText size={14}
                                                                                                              weight={500}
                                                                                                              lineHeight={15}
                                                                                                              color={'#ff5252'}>{'Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹ ÐºÐ¾Ð´'}</SText>
        </div>
        {
            showHint && <div style={{margin: '0 auto', textAlign: 'center'}}>
                <div style={{marginBottom: 17}}><SText size={18} weight={700} lineHeight={23}>{'ÐÐµ Ð¿Ñ€Ð¸Ñ…Ð¾Ð´Ð¸Ñ‚ Ð¡ÐœÐ¡?'}</SText>
                </div>
                <div className={styles.refreshAndWa}>
                    <div onClick={onReenter} className={styles.refreshAndWaBtn}>
                        <div className={styles.circle}>
                            <img src={refresh}/>
                        </div>
                        <div className={styles.textUnderCircle}>
                            <SText size={13} weight={500} lineHeight={15}>{'ÐŸÐ¾Ð¿Ñ€Ð¾Ð±Ð¾Ð²Ð°Ñ‚ÑŒ'}</SText> <br/>
                            <SText size={13} weight={500} lineHeight={15}>{'ÐµÑ‰Ñ‘ Ñ€Ð°Ð·'}</SText>
                        </div>
                    </div>
                    <div onClick={onWA} className={styles.refreshAndWaBtn}>
                        <div className={styles.circle}>
                            <img src={waGreen}/>
                        </div>
                        <div className={styles.textUnderCircle}>
                            <SText size={13} weight={500} lineHeight={15}>{'ÐŸÐ¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ ÐºÐ¾Ð´'}</SText> <br/>
                            <SText size={13} weight={500} lineHeight={15}>{'Ð¿Ð¾ WhatsAPP'}</SText>
                        </div>
                    </div>
                    {width < 1000 && <a href="tel:+74951599009">
                        <div className={styles.refreshAndWaBtn}>
                            <div className={styles.circle}>
                                <img src={phoneIcon}/>
                            </div>
                            <div className={styles.textUnderCircle}>
                                <SText size={13} weight={500} lineHeight={15}>{'ÐŸÐ¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚ÑŒ'}</SText> <br/>
                                <SText size={13} weight={500} lineHeight={15}>{'Ð´Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€Ñƒ'}</SText>
                            </div>
                        </div>
                    </a>}
                </div>
                {width > 1000 && <a href="tel:+74951599009">
                    <div>
                        <SText size={14} weight={500} lineHeight={17}
                               color={Colors.darkGray}>{'Ð˜Ð»Ð¸ Ð¿Ð¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚Ðµ Ð´Ð¸ÑÐ¿ÐµÑ‚Ñ‡ÐµÑ€Ñƒ '}</SText>
                        <img style={{margin: '0 7px 0 12px'}} src={phoneIcon}/>
                        <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>{'8 (985) 555-55-98'}</SText>
                    </div>
                </a>}
            </div>
        }
    </div>

    return <div style={{padding: '80px 24px 25px', width: '100%', position: 'relative'}}>
        <div className={styles.backBtn} onClick={onReenter}><img src={back}/></div>
        <div style={{marginBottom: 3}}><SText size={20} weight={700} color={Colors.darkGray}

                                              lineHeight={25}>{'ÐšÐ¾Ð´ Ð¸Ð· ÑÐ¼Ñ'}</SText>
        </div>
        <div style={{marginBottom: 20}}><SText size={14} weight={500} lineHeight={19}
                                               color={'#7F7F7F'}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ Ð½Ð° Ð½Ð¾Ð¼ÐµÑ€ ' + phone}</SText>
        </div>
        <div style={{border: status === 'error' ? '1px solid #ff5252' : '1px solid #f7f7f7', marginBottom: 13}}
             className={styles.codeInputWrapper}>
            <CodeInput code={code} setCode={setCode}/>
        </div>
        <div style={{marginLeft: 5, opacity: status === 'error' ? 1 : 0}}><SText size={14} weight={500} lineHeight={15}
                                                                                 color={'#ff5252'}>{'Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹ ÐºÐ¾Ð´'}</SText>
        </div>
        {
            showHint && <div style={{marginTop: 100}}>
                <div style={{marginBottom: 15}}><SText size={16} weight={500}>{'ÐÐµ Ð¿Ñ€Ð¸Ñ…Ð¾Ð´Ð¸Ñ‚ ÑÐ¼Ñ?'}</SText></div>
                <div style={{marginBottom: 15, textDecoration: 'underline', cursor: 'pointer'}} onClick={onReenter}><SText
                    size={16}
                    weight={500}>{'ÐŸÐ¾Ð¿Ñ€Ð¾Ð±Ð¾Ð²Ð°Ñ‚ÑŒ ÐµÑ‰Ðµ Ñ€Ð°Ð·'}</SText>
                </div>
                <div style={{marginBottom: 15}}><SText size={16} weight={500}>{'Ð¸Ð»Ð¸ Ð¿Ð¾Ð»ÑƒÑ‡Ð¸Ñ‚ÑŒ '}</SText> <SText
                    size={16} weight={500}
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={onWA}>{'Ð½Ð¾Ð²Ñ‹Ð¹ ÐºÐ¾Ð´ Ð¿Ð¾ WhatsApp'}</SText>
                    <img onClick={onWA} style={{marginLeft: 5, cursor: 'pointer'}} src={waGreen}/></div>
            </div>
        }
    </div>
}

export const ConfirmWA = ({phone = '+7-999-999-99-99', onReenter, status, onDismissError, onSubmit, code, setCode, orderNumber}) => {
    const {get} = useQueryParams()
    const checkout = !!get('auth')?.length

    useEffect(() => {
        onDismissError()
        if (code.length === 4)
            onSubmit(code)
    }, [code])

    if (checkout) return <div>
        <div className={styles.header}>
            <div className={styles.orderTick}>
                <img src={greenTick}/>
                <SText size={18} weight={700} color={Colors.green}>{'Ð’Ð°Ñˆ Ð·Ð°ÐºÐ°Ð· Ð¿Ñ€Ð¸Ð½ÑÑ‚'}</SText>
            </div>
            <div className={styles.orderNumber}>
                <SText size={14} weight={500} lineHeight={17}
                       color={Colors.darkGray}>{'â„– ' + orderNumber}</SText>
            </div>
        </div>
        <div style={{marginBottom: 13, textAlign: 'center'}}><SText size={18} weight={700} lineHeight={23}
                                                                    color={Colors.darkGray}>{'ÐŸÐ¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°'}</SText>
        </div>
        <div className={styles.phoneNumberBlock}>
            <SText size={14} weight={500} lineHeight={17}
                   color={Colors.darkGray}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ðµ Ð² WhatsApp Ñ ÐºÐ¾Ð´Ð¾Ð¼ Ð½Ð° Ð½Ð¾Ð¼ÐµÑ€'}</SText> <br/>
            <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>{phone}</SText> <SText
            onClick={onReenter} className={styles.switchNumber} size={14} weight={500} lineHeight={17}
            color={'#bfbfbf'}>{'Ð´Ñ€ÑƒÐ³Ð¾Ð¹ Ð½Ð¾Ð¼ÐµÑ€'}</SText>
        </div>
        <div style={{border: status === 'error' ? '1px solid #ff5252' : '1px solid #f7f7f7', margin: '0 auto 13px'}}
             className={styles.codeInputWrapper}>
            <CodeInput code={code} setCode={setCode}/>
        </div>
        <div style={{margin: '0 auto 16px', opacity: status === 'error' ? 1 : 0, textAlign: 'center'}}><SText size={14}
                                                                                                              weight={500}
                                                                                                              lineHeight={15}
                                                                                                              color={'#ff5252'}>{'Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹ ÐºÐ¾Ð´'}</SText>
        </div>
        <div style={{margin: '0 auto', textAlign: 'center'}}>
            <div style={{marginBottom: 26}}><SText size={18} weight={700}
                                                   lineHeight={23}>{'ÐÐµ Ð¿Ñ€Ð¸Ñ…Ð¾Ð´Ð¸Ñ‚ Ð¸ Ð½Ð° WhatsApp?'}</SText>
            </div>
            <div style={{textAlign: "center", marginBottom: 9}}>
                <SText size={14} weight={500} lineHeight={17}>{'ÐŸÐ¾Ð¶Ð°Ð»ÑƒÐ¹ÑÑ‚Ð°, Ð¿Ð¾Ð·Ð²Ð¾Ð½Ð¸Ñ‚Ðµ Ð½Ð°Ð¼,'}</SText> <br/>
                <SText size={14} weight={500} lineHeight={17}>{'Ñ‡Ñ‚Ð¾Ð±Ñ‹ Ð¼Ñ‹ Ð¼Ð¾Ð³Ð»Ð¸ Ð¿Ð¾Ð´Ñ‚Ð²ÐµÑ€Ð´Ð¸Ñ‚ÑŒ Ð²Ð°Ñˆ Ð·Ð°ÐºÐ°Ð·'}</SText>
            </div>
            <a href="tel:+74951599009">
                <div>
                    <img style={{margin: '0 7px 0 0'}} src={phoneIcon}/>
                    <SText size={14} weight={500} lineHeight={17} color={Colors.darkGray}>{'8 (985) 555-55-98'}</SText>
                </div>
            </a>
        </div>
    </div>

    return <div style={{padding: '80px 24px 25px', width: '100%', position: 'relative'}}>
        <div className={styles.backBtn} onClick={onReenter}><img src={back}/></div>
        <div style={{marginBottom: 3}}><SText size={20} weight={700} color={Colors.darkGray}

                                              lineHeight={25}>{'ÐšÐ¾Ð´ Ð¸Ð· ÑÐ¾Ð¾Ð±Ñ‰ÐµÐ½Ð¸Ñ Ð² WhatApp'}</SText>
        </div>
        <div style={{marginBottom: 20}}><SText size={14} weight={500} lineHeight={19}
                                               color={'#7F7F7F'}>{'ÐœÑ‹ Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð¸Ð»Ð¸ Ð½Ð° Ð½Ð¾Ð¼ÐµÑ€ ' + phone}</SText>
        </div>
        <div style={{border: status === 'error' ? '1px solid #ff5252' : '1px solid #f7f7f7', marginBottom: 13}}
             className={styles.codeInputWrapper}>
            <CodeInput code={code} setCode={setCode}/>
        </div>
        <div style={{marginLeft: 5, opacity: status === 'error' ? 1 : 0}}><SText size={14} weight={500} lineHeight={15}
                                                                                 color={'#ff5252'}>{'Ð½ÐµÐ²ÐµÑ€Ð½Ñ‹Ð¹ ÐºÐ¾Ð´'}</SText>
        </div>

        <a href="tel:+74951599009">
            <div style={{marginTop: 137, cursor: 'pointer'}}><SText size={14} weight={500}
                                                                    color={'#bfbfbf'}>{'Ð¡Ð²ÑÐ·Ð°Ñ‚ÑŒÑÑ Ñ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶ÐºÐ¾Ð¹'}</SText>
            </div>
        </a>
    </div>
}

export default Auth